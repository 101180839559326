import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Pin, Props as PinProps } from './Pin'

export interface Props {
  address?: string
  googleMapsURL?: string
  image?: ImageProps
  languageCode: string
  pin?: PinProps[]
  title?: string
}

export const Map = memo(function Map({
  address,
  googleMapsURL,
  image,
  languageCode,
  pin,
  title,
}: Props) {
  return (
    <Container>
      <Head>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        {address ? (
          <FadeInUp>
            <Address dangerouslySetInnerHTML={{ __html: address }} />
          </FadeInUp>
        ) : null}
      </Head>

      <Wrapper row stretch wrap>
        <LeftSide>
          {image ? (
            <LazyLoadComponent>
              <Image {...image} />
            </LazyLoadComponent>
          ) : null}

          {googleMapsURL ? (
            <CTA
              label={useVocabularyData('google-maps', languageCode)}
              rel="noopener"
              target="_blank"
              URL={googleMapsURL}
              variant="flat"
            />
          ) : null}
        </LeftSide>

        <RightSide>
          {pin ? pin.map((item, index) => <Pin key={index} {...item} />) : null}
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section``

const Head = styled.div`
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  padding: 5rem 3.75rem 4.6875rem 3.75rem;

  @media (max-width: 1199px) {
    padding: 2.5rem 1.875rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 6.875rem;
  line-height: 6.875rem;

  @media (max-width: 1199px) {
    font-size: 3.125rem;
    line-height: 3.125rem;
  }
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.6875rem;
`

const Wrapper = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryDark};
`

const LeftSide = styled.div`
  width: 66%;
  min-height: 80vh;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: 100%;
    height: 60vh;
    min-height: auto;
  }
`

const CTA = styled(Button)`
  position: absolute;
  bottom: 4.125rem;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1199px) {
    bottom: 1.875rem;
  }
`

const RightSide = styled.div`
  width: 34%;
  padding: 5.25rem 3.75rem 5.25rem 6.5625rem;

  @media (max-width: 1199px) {
    width: 100%;
    padding: 2.5rem 1.875rem;
  }
`
