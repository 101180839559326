import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Place } from 'app/components/Icons'
import React, { memo } from 'react'

export interface Props {
  distance?: string
  title?: string
}

export const Pin = memo(function Pin({ distance, title }: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      <FadeInUp>
        <Place />

        <Wrapper>
          {title ? <Title>{title}</Title> : null}

          {distance ? <Distance>{distance}</Distance> : null}
        </Wrapper>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.div`
  margin-top: 3.75rem;
  &:first-of-type {
    margin-top: 0;
  }

  > div {
    width: 100%;
    padding-left: 2.75rem;
    position: relative;
  }

  svg {
    width: auto;
    height: 2.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight5};
    position: absolute;
    top: 0.25rem;
    left: 0;
  }

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.5rem;
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
`

const Distance = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
`
